import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
// @ts-ignore
import { Media } from 'gatsby-plugin-fresnel';
import classNames from 'classnames';
import RestartPopup from '../../components/general/RestartPopup';
import DetailsTableUser from '../../components/general/DetailsTableUser';
import Filters from '../../components/general/Filters';
import Header from '../../components/ui/Header';
import CalculationLayout from '../../components/layouts/CalculationLayout';

// @ts-ignore
import { ReactComponent as CloseIcon } from '../../assets/vectors/CloseIcon.svg';
import {
  CREATE_BUSINESS,
  CREATE_RESIDENCE,
} from '../../apollo/queries';
import {
  getBillPresenceType,
  getBusinessId,
  getHouseholdSize,
  getLocalityLS,
  getResidenceId,
  getTariffType,
  getUserType,
  removeRegionPostalCode,
  setActiveStepUrlLS,
  setBusinessId,
  setResidenceId,
  setResultsToken,
} from '../../utils/localStorage/localStorageFunctions';
import { getActiveResultFiltersSS } from "../../utils/sessionStorage/sessionStorageFunctions";
import {
  getCalculationInput,
} from '../../utils/calculationUtils';
import {
  CreateResidence,
  CreateResidenceVariables,
} from '../../apollo/__generated__/CreateResidence';
import {
  PlanFeatureFilters,
  StepUrl,
  TARIFF_TYPE,
} from '../../utils/enums';
import {
  navigateAndSetActiveStep,
} from '../../utils/utils';
import { FiltersType } from '../../utils/sessionStorage/sessionStorageTypes';
import {
  CreateBusiness,
  CreateBusinessVariables,
} from '../../apollo/__generated__/CreateBusiness';
import ErrorPopup from '../../components/general/ErrorPopup';
import usePreloadPageData from '../../hooks/usePreloadPageData';
import SEOLayout from '../../components/layouts/SEOLayout';
import { EmailPopup } from '../../components/general/EmailPopup';
import EnergyPlanList, { IEnergyPlanListData, INITIAL_ENERGY_PLAN_LIST_DATA } from '../../components/ui/EnergyPlanList';
import Typography from '../../components/ui/Typography';

const style = require('../Results.module.scss');

const Results = (props: any) => {
  const { location } = props;
  const resendQueries = location?.state?.resendQueries;
  // const resetFilters = location?.state?.resetFilters;

  const token = useMemo(() => {
    const getParams = new URLSearchParams(location?.search);
    return getParams.get('token');
  }, []);

  const [residenceOrBusinessId, setResidenceOrBusinessId] = useState('');
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [listData, setListData] = useState<IEnergyPlanListData>(INITIAL_ENERGY_PLAN_LIST_DATA);
  const { actualPlan, savings = 0 } = listData;

  const [postcode, setPostcode] = useState<string | undefined>(() =>
    !token ? getLocalityLS()?.postcode?.postcode : undefined
  );

  const afterLoadData = (data: IEnergyPlanListData, extra: any) => {
    setListData(data)
    if (extra?.postcode) {
      setPostcode(extra?.postcode);
    }
  }

  const [showEmailPopup, setShowEmailPopup] = useState<boolean | null>(null);

  useEffect(() => {
    const status = localStorage.getItem('popup_status');
    if (status || token) {
      setShowEmailPopup(false);
    } else {
      setTimeout(() => setShowEmailPopup(true), 6000);
    }

    // clear region postalCode
    removeRegionPostalCode();
  }, []);

  const retailer = actualPlan?.plan?.retailer;
  const distributor = actualPlan?.plan?.distributor;


  const [pageHeadingText, setPageHeadingText] = useState(
    'Your estimated saving is $xx per year'
  );
  const [pageSubHeadingText, setPageSubHeadingText] = useState(
    'Save $xx/month - comparing the best to the median plan offer'
  );
  const [householdSize] = useState<number | null>(() => getHouseholdSize());

  

  // Initial filters
  const [activeFilters, setActiveFilters] = useState<FiltersType>(() => {
    const activeResultFiltersLS = getActiveResultFiltersSS(location.pathname);

    // Get filters from LS
    if (activeResultFiltersLS && !token) {
      return activeResultFiltersLS;
    }

    // Recalculate filters

    const billPresenceType = getBillPresenceType();
    const tariffType = getTariffType();

    const planType = [];

    if (billPresenceType === 'noBill' || tariffType === 'single') {
      planType.push(TARIFF_TYPE.SR);
    } else {
      planType.push(TARIFF_TYPE.SR, TARIFF_TYPE.TOU, TARIFF_TYPE.BLK);
    }

    const filtersInitialValue: FiltersType = {
      planType,
      planFeature: [PlanFeatureFilters.onlyBestOfferPerRetailer, PlanFeatureFilters.guaranteedDiscountRequired],
    };

    return filtersInitialValue;
  });

  const [extraOpenOverlay, setExtraOpenOverlay] = useState('');

  const [isRestartPopupVisible, setIsRestartPopupVisible] = useState(false);
  const [isErrorPopupVisible, setIsErrorPopupVisible] = useState(false);

  const [createResidence] = useMutation<
    CreateResidence,
    CreateResidenceVariables
  >(CREATE_RESIDENCE);

  const [createBusiness] = useMutation<CreateBusiness, CreateBusinessVariables>(
    CREATE_BUSINESS
  );

  /**
   * Optionally send queries to create Residence or Business
   */
  const createResidenceOrBusiness = () => {
    const residenceIdLS = getResidenceId();
    const businessIdLS = getBusinessId();
    const userTypeLS = getUserType();

    // If not coming from previous steps look for values in LS
    // and go directly to Recommendation
    if (!resendQueries) {
      if (residenceIdLS) {
        setResidenceOrBusinessId(residenceIdLS);
        return;
      }
      if (businessIdLS) {
        setResidenceOrBusinessId(businessIdLS);
        return;
      }
    }

    // If coming from next steps (Plan Info, Almost there), send the mutation
    const calcInput = getCalculationInput();

    // Mutation for Household
    if (userTypeLS === 'household') {
      createResidence({ variables: { residenceData: calcInput } })
        .then((value) => {
          const residenceId = value.data?.createResidence?.newResidence?.id;

          if (!residenceId) return;

          setResidenceId(residenceId);
          setResidenceOrBusinessId(residenceId);
        })
        .catch(() => {
          setIsErrorPopupVisible(true);
        });
      return;
    }

    // Mutation for Business
    if (userTypeLS === 'business') {
      createBusiness({ variables: { businessData: calcInput } })
        .then((value) => {
          const businessId = value.data?.createBusiness?.newBusiness?.id;

          if (!businessId) return;

          setBusinessId(businessId);
          setResidenceOrBusinessId(businessId);
        })
        .catch(() => {
          setIsErrorPopupVisible(true);
        });
    }
  };

  useEffect(() => {
    if (listData.err) {
      setIsErrorPopupVisible(true)
    };
  }, [listData.err]);

  /**
   *
   * Entry point
   *
   */
  useEffect(() => {
    if (token) {
      setResultsToken(token);
      setShowEmailPopup(false);
      setActiveStepUrlLS(StepUrl.URL_RESULTS);
      return;
    }
    createResidenceOrBusiness();
  }, [token]);

  useEffect(() => {
    if (savings > 0) {
      setPageHeadingText(
        `Your estimated saving is $${Math.ceil(savings)} per year`
      );
      if (listData.isActualPlanUserSelectedData) {
        setPageSubHeadingText(
          `Save $${Math.round(
            Math.ceil(savings) / 12
          )}/month - comparing the best value plan to your current plan with ${retailer?.orgName ?? 'current retailer'
          }`
        );
        return;
      }
      setPageSubHeadingText(
        `Save $${Math.round(
          Math.ceil(savings) / 12
        )}/month - comparing the best to the median plan offer`
      );
      return;
    }
    setPageHeadingText(`Your estimated saving is $... per year`);
    setPageSubHeadingText(
      'We are comparing from over 2000 plans, to find the ones that better suit your needs.'
    );
  }, [savings, retailer, listData.isActualPlanUserSelectedData]);

  //
  // UI
  //
  const toggleExtraOpenOverlay = (overlayName: 'details' | 'filters') => {
    setExtraOpenOverlay((prevState) => {
      if (prevState === overlayName) return '';
      return overlayName;
    });
  };

  const handleEditDetails = () => {
    setIsRestartPopupVisible(true);
  };

  const handleRestart = () => {
    const userType = getUserType();

    if (userType === 'business') {
      navigateAndSetActiveStep(StepUrl.URL_POSTCODE_BUSINESS);
      return;
    }

    navigateAndSetActiveStep(StepUrl.URL_POSTCODE_HOUSEHOLD);
  };

  // TODO: fix IOS Safari
  useEffect(() => {
    const htmlTag = document.getElementsByTagName('html')[0];
    const bodyTag = document.getElementsByTagName('html')[0];
    if (!htmlTag || !bodyTag) return;

    if (extraOpenOverlay) {
      htmlTag.style.overflow = 'hidden';
      bodyTag.style.overflow = 'hidden';
    } else {
      htmlTag.style.overflow = 'visible';
      bodyTag.style.overflow = 'visible';
    }
  }, [extraOpenOverlay]);

  usePreloadPageData(
    StepUrl.URL_POSTCODE_HOUSEHOLD,
    StepUrl.URL_POSTCODE_BUSINESS,
    StepUrl.URL_PLAN_INFO,
    StepUrl.URL_ALMOST_THERE
  );

  useEffect(() => {
    const retailerPopup = localStorage.getItem('retailer_popup');
    if (!retailerPopup) {
      localStorage.setItem('retailer_popup', '');
    }
  }, []);

  return (
    <SEOLayout>
      <CalculationLayout isCalculating={listData.loading}>
        <RestartPopup
          headingText="Edit your details"
          paragraphText="Would you like to edit your details?"
          isRestartPopupVisible={isRestartPopupVisible}
          setIsRestartPopupVisible={setIsRestartPopupVisible}
          onRestart={handleRestart}
        />
        <ErrorPopup
          headingText="Sorry, an error has occurred"
          paragraphText="Would you like to restart your sign-up process?"
          isRestartPopupVisible={isErrorPopupVisible}
          setIsRestartPopupVisible={setIsErrorPopupVisible}
          onRestart={handleRestart}
        />
        {showEmailPopup && <EmailPopup activeFilters={activeFilters} togglePopup={(value: boolean) => setShowEmailPopup(value)} />}
        <div>
          <Header hasNav/>
          <div className={style.wrapper}>
            <div className={style.leftSection}>
              <div>
                <Media lessThan="desktop">
                  {!listData.totalPlans && savings ? (
                    <h3 className="my-2">
                      Please change your filter selection
                    </h3>
                  ) : (
                    <h3 className="my-2">{pageHeadingText}</h3>
                  )}
                </Media>
                <Media greaterThanOrEqual="desktop">
                  {!listData.totalPlans && savings ? (
                    <h1 className="my-2">
                      Please change your filter selection
                    </h1>
                  ) : (
                    <h1 className="my-2" style={{ marginTop: '0' }}>
                      {pageHeadingText}
                    </h1>
                  )}
                </Media>
                {!!listData.totalPlans && (
                  <Typography className="mt-4 mb-2" tag="h3">
                    There are {listData.totalPlans} plans that meet your selections
                  </Typography>
                )}                
                {!listData.totalPlans && savings ? (
                  <p className="sub-heading">
                    There are no plans that meet the criteria of your search and
                    filter selections
                  </p>
                ) : (
                  <p className="sub-heading">{pageSubHeadingText}</p>
                )}
              </div>
              <EnergyPlanList
                pathname={location.pathname}
                token={token}
                residenceOrBusinessId={residenceOrBusinessId}
                activeFilters={activeFilters}
                allowToLoad={!!token || residenceOrBusinessId !== ""}
                onLoadCompleted={afterLoadData}
                onLoadingFilters={setLoadingFilters}
              />
            </div>
            <div className={style.rightSection}>
              <div className={style.yourDetails}>
                <h4>Your details</h4>
                <DetailsTableUser
                  householdSize={householdSize ?? 0}
                  distributorName={distributor?.name ?? ''}
                  retailerName={
                    listData.isActualPlanUserSelectedData ? retailer?.orgName : null
                  }
                  postCode={postcode ?? ''}
                  totalUsage={actualPlan?.annualTotalUsage}
                  controlledLoadUsage={actualPlan?.annualControlledLoad}
                  solarExport={actualPlan?.annualSolarExportKwh}
                  onRestart={handleEditDetails}
                />
              </div>
              <Media greaterThanOrEqual="desktop">
                {(mediaClassNames: any, renderChildren: any) => (
                  <aside
                    className={classNames(style.sideMenu, mediaClassNames)}
                  >
                    {renderChildren && (
                      <>
                        <div className={style.filtersSection}>
                          <h4>Filters</h4>
                          <Filters
                            setActiveFilters={setActiveFilters}
                            activeFilters={activeFilters}
                            disabled={loadingFilters}
                          />
                        </div>
                      </>
                    )}
                  </aside>
                )}
              </Media>
            </div>
          </div>
        </div>
        <Media lessThan="desktop">
          {(mediaClassNames: any, renderChildren: any) => (
            <div className={classNames(style.bottomOverlay, mediaClassNames)}>
              {extraOpenOverlay && renderChildren && (
                <>
                  <div className={style.bottomOverlayHeading}>
                    {extraOpenOverlay === 'details' && (
                      <h4 style={{ marginBottom: '30px' }}>Your details</h4>
                    )}
                    {extraOpenOverlay === 'filters' && <h4>Filters</h4>}
                    <div
                      className={style.bottomOverlayCloseButton}
                      onClick={() => {
                        setExtraOpenOverlay('');
                      }}
                    >
                      <CloseIcon width={24} height={24} />
                    </div>
                  </div>
                  <div className={style.bottomOverlayContent}>
                    {extraOpenOverlay === 'details' && (
                      <DetailsTableUser
                        householdSize={householdSize ?? 0}
                        distributorName={distributor?.name ?? ''}
                        retailerName={
                          listData.isActualPlanUserSelectedData
                            ? retailer?.orgName
                            : null
                        }
                        postCode={postcode ?? ''}
                        totalUsage={actualPlan?.annualTotalUsage}
                        controlledLoadUsage={actualPlan?.annualControlledLoad}
                        solarExport={actualPlan?.annualSolarExportKwh}
                        onRestart={handleEditDetails}
                      />
                    )}
                    {extraOpenOverlay === 'filters' && (
                      <Filters
                        style={{ marginBottom: '15px' }}
                        setActiveFilters={setActiveFilters}
                        activeFilters={activeFilters}
                        disabled={loadingFilters}
                      />
                    )}
                  </div>
                </>
              )}
              <div className={style.bottomOverlayButtons}>
                <div
                  onClick={() => {
                    toggleExtraOpenOverlay('details');
                  }}
                  className={extraOpenOverlay === 'details' ? style.active : ''}
                >
                  Your details
                </div>
                <div
                  onClick={() => {
                    toggleExtraOpenOverlay('filters');
                  }}
                  className={extraOpenOverlay === 'filters' ? style.active : ''}
                >
                  Filters
                </div>
              </div>
            </div>
          )}
        </Media>
      </CalculationLayout>
    </SEOLayout>
  );
};

export default Results;
