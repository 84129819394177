import React from 'react';
import clamp from 'lodash-es/clamp';

const style = require('./Stepper.module.scss');

type StepperProps = {
  steps: string[];
  activeStage: number;
  progress: number;
  className?: string;
  [otherProps: string]: any;
};

const Stepper = (props: StepperProps) => {
  const { steps, activeStage, progress, className, ...rest } = props;

  return (
    <div {...rest} className={`${style.stepperComponent} ${className}`}>
      <div className={style.stepsContainer}>
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div
              className={
                activeStage > index ? style.stepComplete : style.stepPending
              }
            >
              <div className={style.stepCircle}>
                <div className={style.stepNumber}>{index + 1}</div>
              </div>
              {index + 1 === activeStage && (
                <div className={style.stepText}>{step}</div>
              )}
            </div>
            {index + 1 === activeStage && <div className="horizontal-spacer" />}
          </React.Fragment>
        ))}
      </div>
      <div className={style.progressBar}>
        <div
          className={style.progressCompleted}
          style={{ width: `${clamp(progress, 0, 100)}%` }}
        />
        <div
          className={style.progressPending}
          style={{ width: `${100 - clamp(progress, 0, 100)}%` }}
        />
      </div>
    </div>
  );
};

Stepper.defaultProps = {
  className: '',
};

export default Stepper;
